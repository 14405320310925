// ** React & Mui
import { Box, Card, Typography, Button, Stack, Avatar, Chip, useTheme, useMediaQuery, ChipProps, Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';

// ** Context & Utils
import { useModalsActions } from '@/context/modals';
import { UniswapWidgetPayload } from '../modals/uniswap-widget';
import { getChainByNetworkName } from '@/utils/uniswap/constants';
import { useSession } from '@/context/session';
import { NotConnectedCase } from '../modals/not-connected';
import { UniswapTokenStatsData } from '@/types/custom';
import { IconArrowDownRight, IconArrowRight, IconArrowUpRight } from '@tabler/icons-react';
interface TokenProps {
  token: UniswapTokenStatsData;
  isOverview?: boolean;
}
const getNetworkChipColor = (network: string) => {
  const networkColors = {
    polygon: {
      bgcolor: '#8626D1',
      color: 'white'
    },
    arbitrum: {
      bgcolor: '#0043A9',
      color: '#9EC5FF'
    },
    optimism: {
      bgcolor: '#E4080A',
      color: 'white'
    },
    base: {
      bgcolor: '#0167FF',
      color: 'white'
    }
  };
  return networkColors[network as keyof typeof networkColors] || null;
};
const formatLargeNumber = (num: number): string => {
  const absNum = Math.abs(num);
  if (absNum >= 1e9) {
    return `${(num / 1e9).toFixed(1)}B`;
  } else if (absNum >= 1e6) {
    return `${(num / 1e6).toFixed(1)}M`;
  } else if (absNum >= 1e3) {
    return `${(num / 1e3).toFixed(1)}K`;
  }
  return num.toString();
};
const UniswapCard = ({
  token,
  isOverview
}: TokenProps) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    open: openModal
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged
  } = useSession();
  const handleBuyToken = (tokenValue: UniswapTokenStatsData) => {
    if (notLoggedIn) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      openModal('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    const tokenChain = getChainByNetworkName(tokenValue.chain);
    if (!tokenChain) return;
    const tokenMarkets = tokenValue.markets.map(m => m.identifier);
    const isV3 = tokenMarkets.some(tm => tm.includes('v3'));
    const isV2 = tokenMarkets.some(tm => tm.includes('v2'));
    if (!isV3 && !isV2) return;
    const marketVersion = isV2 ? 2 : 3;
    const uniswapPayload: UniswapWidgetPayload = {
      marketVersion,
      chainId: tokenChain.id,
      tokenAddress: tokenValue.address,
      tokenDecimals: tokenValue.decimals,
      tokenSymbol: tokenValue.symbol,
      tokenImg: tokenValue.image ?? undefined
    };
    openModal('uniswapWidget', uniswapPayload);
  };
  const chipSx: ChipProps['sx'] = {
    fontStyle: 'italic',
    fontWeight: 700,
    px: 1,
    height: 28,
    ...getNetworkChipColor(token.chain),
    opacity: 0.9
  };
  const priceChanged24h = token.price.priceChangePercentage24h;
  return <Card sx={{
    width: isOverview && isXsScreen ? '91vw' : undefined,
    p: 3,
    height: '100%',
    minHeight: '280px',
    background: theme => theme.palette.mode === 'dark' ? 'linear-gradient(180deg, rgba(47, 51, 66, 0.95) 0%, rgba(41, 44, 60, 0.95) 100%)' : 'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(247, 249, 252, 0.95) 100%)',
    backdropFilter: 'blur(8px)',
    border: theme => `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)'}`,
    '&:hover': {
      background: theme => theme.palette.mode === 'dark' ? 'linear-gradient(180deg, rgba(52, 56, 74, 0.95) 0%, rgba(47, 51, 66, 0.95) 100%)' : 'linear-gradient(180deg, rgba(255, 255, 255, 0.98) 0%, rgba(247, 249, 252, 0.98) 100%)'
    }
  }} data-sentry-element="Card" data-sentry-component="UniswapCard" data-sentry-source-file="UniswapCard.tsx">
      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      gap: {
        xs: 3,
        md: 4
      }
    }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
        {/* Header Section */}
        <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
        gap: 2
      }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
          {/* Token Icon */}
          <Box sx={{
          width: 56,
          height: 56,
          borderRadius: '14px',
          background: theme => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.2)}, ${alpha(theme.palette.primary.light, 0.3)})`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 1.2,
          flexShrink: 0
        }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
            <Avatar src={token.image ?? '/images/icons/token.png'} alt={token.symbol} sx={{
            width: '100%',
            height: '100%',
            backgroundColor: !token.image ? '#fff' : 'transparent',
            '& img': {
              objectFit: 'contain',
              width: '85%',
              height: '85%'
            }
          }} data-sentry-element="Avatar" data-sentry-source-file="UniswapCard.tsx" />
          </Box>

          {/* Token Info */}
          <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1
        }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
            <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Typography variant="h5" component="div" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                {token.symbol}
              </Typography>
              <Chip label={token.chain} size={isSmScreen ? 'small' : 'medium'} sx={chipSx} data-sentry-element="Chip" data-sentry-source-file="UniswapCard.tsx" />
            </Box>
            <Typography variant="caption" fontWeight={600} fontSize={{
            xs: 12,
            sm: 14
          }} color="text.secondary" sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 0.5
          }} data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
              <span style={{
              color: priceChanged24h === 0 ? 'inherit' : priceChanged24h > 0 ? 'green' : 'red'
            }}>
                {`${priceChanged24h}%`}
              </span>
              {priceChanged24h === 0 ? <IconArrowRight size={16} /> : priceChanged24h > 0 ? <IconArrowUpRight size={16} /> : <IconArrowDownRight size={16} />}
              {`24hr`}
            </Typography>
          </Box>
        </Box>

        {/* Cards Grid */}
        <Grid container spacing={2} data-sentry-element="Grid" data-sentry-source-file="UniswapCard.tsx">
          <Grid item xs={12} xl={6} sx={{
          display: 'flex'
        }} data-sentry-element="Grid" data-sentry-source-file="UniswapCard.tsx">
            <Box sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f2',
            p: {
              xs: 1.5,
              sm: 2
            },
            borderRadius: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Stack alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                <Typography variant="body1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                  Bought by
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                  <Typography variant="h6" color="#2EE7D1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                    {`${Math.round(token.buyers).toLocaleString()}`}
                  </Typography>
                  <Typography data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">🚀</Typography>
                </Stack>
                <Typography variant="body2" color="text.primary" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                  Lens users 24hs
                </Typography>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} xl={6} sx={{
          display: 'flex'
        }} data-sentry-element="Grid" data-sentry-source-file="UniswapCard.tsx">
            <Box sx={{
            bgcolor: theme.palette.mode === 'dark' ? '#39414e' : '#f2f2f2',
            p: 2,
            borderRadius: 2,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
              <Stack alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                <Typography variant="body1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                  Market Cap
                </Typography>
                <Stack direction="row" spacing={0.5} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="UniswapCard.tsx">
                  <Typography variant="h6" color="#2EE7D1" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">
                    ${formatLargeNumber(Math.round(token.price.marketCap))}
                  </Typography>
                  <Typography data-sentry-element="Typography" data-sentry-source-file="UniswapCard.tsx">🔥</Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Buy Button */}
        <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }} data-sentry-element="Box" data-sentry-source-file="UniswapCard.tsx">
          <Button variant="contained" size="large" sx={{
          width: '56%',
          textTransform: 'none',
          fontSize: '1rem',
          padding: '8px',
          background: () => `linear-gradient(135deg, #3B82F6 0%, #2563EB 100%)`,
          '&:hover': {
            background: () => `linear-gradient(135deg, #2563EB 0%, #1D4ED8 100%)`
          },
          boxShadow: '0 4px 12px rgba(59, 130, 246, 0.25)',
          color: 'white',
          fontWeight: 600
        }} onClick={() => handleBuyToken(token)} data-sentry-element="Button" data-sentry-source-file="UniswapCard.tsx">
            Buy
          </Button>
        </Box>
      </Box>
    </Card>;
};
export default UniswapCard;